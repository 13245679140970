import React, { ReactNode } from 'react'
import { Footer } from '../components/Footer'
import { HeaderStatic } from '../components/Header'
import { SocialsSection } from '../components/SocialsSection'
import '../styles/index.scss'

interface Props {
  children?: ReactNode
}

export const JobLayout = ({ children }: Props) => (
  <main>
    <HeaderStatic />
    <div>{children}</div>
    <SocialsSection />
    <Footer />
  </main>
)
