import React from 'react'
import { Section404 } from '../components/Section404'
import { SEO } from '../components/SEO'
import { JobLayout } from '../layouts/JobLayout'

const Page404 = () => (
  <JobLayout>
    <SEO title="Page not found" />
    <Section404 />
  </JobLayout>
)

export default Page404
