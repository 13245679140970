import { Link } from 'gatsby'
import React from 'react'

export const Section404 = () => (
  <div className="section-full-article">
    <div
      className="container section-full-article"
      style={{
        maxWidth: 800,
        textAlign: 'center',
        marginTop: 80,
        marginBottom: 80,
        color: 'black',
      }}
    >
      <h1>404 - Page not found</h1>
      <p>
        The page you are looking for might have been removed had its name changed or is temporarily
        unavailable
      </p>
      <Link to="/" className="btn btn-primary">
        Back to homepage
      </Link>
    </div>
  </div>
)
